import { isAfter, isBefore } from "date-fns";

import { Brand } from "types";

const promotionStartDate = new Date("2024-10-31T11:00:00+01:00");
const promotionEndDate = new Date("2024-11-22T00:00:00+01:00");

export const isAssetPromotionEnabled = (
  assetId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand: Brand,
): boolean => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  return (
    isAfterPromotionStart &&
    isBeforePromotionEnd &&
    [
      "ba660e94-42a7-4679-abf4-33ebe7108bc8", // Líbere Bilbao La Vieja
      "167f2b95-95de-4b29-bbab-5a2ab5a6db39", // Líbere Bilbao Ledesma
      "42490f16-14ee-43f3-9b11-90f1c2f3b2f4", // Líbere Pamplona Yamaguchi
      "d1e4c1d6-14f5-402b-bb0c-4f0aa5b821d6", // Líbere Vitoria
    ].includes(assetId)
  );
};

export const promotionForAsset = (
  assetId: string,
  brand: Brand,
): Promotion | null =>
  isAssetPromotionEnabled(assetId, brand) ? promotionForBrand(brand) : null;

export const promotionForBrand = (brand: Brand): Promotion | null => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  if (brand.id === "libere" && isAfterPromotionStart && isBeforePromotionEnd) {
    return {
      discount: { type: "fixed", amount: 10 },
      promoCode: "NOV10",
      startDate: "01/11/2024",
      endDate: "30/11/2024",
      maxNights: 3,
      loyaltyApplies: true,
    };
  }
  return null;
};

export type Promotion = {
  discount: { type: "fixed" | "upTo"; amount: number };
  promoCode: string;
  startDate: string;
  endDate: string;
  maxNights: number;
  translateKey?: string;
  loyaltyApplies?: boolean;
};
